import { graphql } from 'gatsby';
import { object, shape } from 'prop-types';
import React, { Fragment } from 'react';

import { Fold, Helmet, Layout } from 'ui/partials';
import { Copy, Masonry, Separator, TextBlock, TextString, Title } from 'ui/components';

const NoteTpl = props => {
  const { html, frontmatter } = props.data.markdownRemark;
  const { legacy, title } = frontmatter;
  return (
    <Fragment>
      <Helmet {...props} title={`${frontmatter.title} ⋅ Notes`} />
      <Layout {...props}>
        <Fold>
          <TextBlock>
            <Title>
              <TextString looks="hcap">From the notebook</TextString>
              <TextString looks="h1">{title}</TextString>
            </Title>
          </TextBlock>
        </Fold>
        <Separator ornamental size="h" />
        {legacy ? (
          <Copy dangerouslySetInnerHTML={{ __html: html }} />
        ) : (
          <Masonry dangerouslySetInnerHTML={{ __html: html }} />
        )}
      </Layout>
    </Fragment>
  );
};

export default NoteTpl;

NoteTpl.propTypes = {
  data: shape({
    markdownRemark: object.isRequired,
  }).isRequired,
};

export const pageQuery = graphql`
  query NoteByUID($uid: String!) {
    markdownRemark(frontmatter: { uid: { eq: $uid } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        legacy
        title
        uid
      }
    }
  }
`;
